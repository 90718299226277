import SwaggerUI from "swagger-ui"

const URLS = {
  production: 'https://api.finary.com/admin/doc',
  staging: 'https://api.finary.dev/admin/doc',
}

const CLIENT_ID = {
  production: process.env.REACT_APP_ADMIN_API_CLIENT_ID_PRODUCTION,
  staging: process.env.REACT_APP_ADMIN_API_CLIENT_ID_STAGING,
}

const CLIENT_SECRET = {
  production: process.env.REACT_APP_ADMIN_API_CLIENT_SECRET_PRODUCTION,
  staging: process.env.REACT_APP_ADMIN_API_CLIENT_SECRET_STAGING,
}

const App = () => {
  const loadDoc = (env) => {
    const url = URLS[env];
    console.log({CLIENT_ID});
    console.log({CLIENT_SECRET});

    const headers = new Headers({
      'CF-Access-Client-Id': CLIENT_ID[env],
      'CF-Access-Client-Secret': CLIENT_SECRET[env],
    });
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: headers
    }).then(res => res.json())
      .then((res) => {
        console.log(res)
        SwaggerUI({ dom_id: '#docsRoot', spec: res  })
      })
  }
  return (
    <div>
      <button onClick={() => loadDoc('production')}>
        Production
      </button>
      <button onClick={() => loadDoc('staging')}>
        Staging
      </button>
    </div>
  );
}
export default App;
