import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

